.parent {
  height: 25px;
  width: calc(100% - 20px);
  padding-top: 15px;
  padding-left: 15px;
  background-color: white;
}

.progress {
  width: calc(100% - 20px);
  height: 20px;
  background-color: #e4e4e4;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
}

.progressBar {
  transition: width 0.5s linear;
  height: 20px;
  border-radius: 10px;

  &_p1 {
    @extend .progressBar;
    background-color: #e14343;
  }

  &_p2 {
    @extend .progressBar;
    background-color: rgba(244, 198, 47, 0.87);
  }

  &_p3 {
    @extend .progressBar;
    background-color: #49c54f;
  }
}

.progressText {
  margin-left: 10px;
}
