.category {
  margin: 0 16px 0 16px;
  min-width: 360px;
  height: 44px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
}

.category + .category {
  border-style: solid none none none;
  border-width: 1px;
  border-color: rgba(197, 185, 185, 0.2);
  box-sizing: border-box;
}

.title {
  flex-grow: 1;
  font-size: 14px;
  line-height: 43px;
  color: rgba(0, 0, 0, 0.87);
}

.subtotal {
  line-height: 43px;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.button {
  margin-left: 20px;
  flex: 0 0 50px;
}

.icon {
  margin: auto;
  width: 30px;
}
