.resultContainer {
  background: #6dd172;
  h2 {
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    padding: 16px 16px 0 16px;
  }
}

.return {
  padding: 0 0 96px 12px;
  font-size: 80%;
  a {
    color: #666666;
    text-decoration: none;
    &:hover {
      color: #111111;
    }
  }
}
.attention {
  padding: 16px 12px;
  font-size: 80%;
  color: #333333;
}
.chart {
  background: #ffffff;
  margin: 16px;
  border-radius: 12px;
  padding-bottom: 1px;
}

.tabs {
  display: flex;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  /*
  margin: 0 auto 0 auto;*/
  /*  width: 300px;*/
}

.tabPanel {
  padding: 16px;
}

.summary {
  margin: 0 20px 10px 20px;
  padding-top: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* GRAY_SCALE/NON_TRANSPARENT/BLACK */

  color: #212121;
}

.selectAgeContainer {
  display: flex;
  margin: 20px;
}

.showAsset {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
}

.showAssetPlus {
  @extend .showAsset;
  color: black;
}

.showAssetMinus {
  @extend .showAsset;
  color: rgba(224, 69, 69, 0.87);
}

.preSection {
  color: #666666;
  font-size: 15px;
  font-weight: 700;
  margin: 48px 16px 8px 16px;
}

.sectionTitle {
  color: #212121;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin: 8px 16px 8px 16px;
}
