.container {
  background-color: #e9f8ea;
  text-align: center;
  font-size: 14px;
  padding: 24px;
  margin: 0 12px 18px 12px;
  h2 {
    margin: 0 0 16px 0;
  }
}

.badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.surplus {
  text-align: center;
}
.title {
  margin-top: 8px;
}
.badge {
  width: 100px;
  margin-bottom: 16px;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
  }
  div {
    font-size: 8px;
    white-space: pre;
    text-align: center;
  }
  .icon {
    height: 64px;
    width: 80px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

.badgeCount {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0px;
  bottom: 0px;
  background: #b40707;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  border-radius: 16px;
  line-height: 32px;
}
