$themeColor: #5abc60;
.link {
/*  position: absolute;*/
  min-width: 10em;
  height: 48px;
  line-height: 48px;
  color: $themeColor;
  cursor: pointer;
  font-size: 1rem;
  top: 7px;
  left: 16px;
  text-align: center;
}

.small {
  /*  position: absolute;*/
    color: $themeColor;
    cursor: pointer;
    text-align: center;
}
  