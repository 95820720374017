$backgroundColor: #ffffff;
$foregroundColor: #ecf7ec;
$fontColor: #62b154;

.radioArea {
  text-align: center;
}
.selectBtn {
  display: inline-block;
  margin: 0 8px 4px 0;

  span {
    min-width: 160px;
    height: 56px;
    text-align: center;
    font-weight: bold;
    vertical-align: middle;
    display: table-cell;
    overflow: hidden;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    color: $fontColor;
    background: $backgroundColor;
    border: 4px #def1de solid;
    &:hover {
      background-color: #f7fcf7;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
    &:checked + span {
      color: $fontColor;
      background: $foregroundColor;
    }
  }
}
