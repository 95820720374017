.container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 8px;
  > div {
    font-size: 70%;
    text-align: right;
  }
  th {
    text-align: center;
  }
}

.tableWrapper {
  position: relative;

  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #6dd172;
    border-radius: 3px;
  }

  table {
    white-space: nowrap;
  }

  th {
    background: #f2f2f2;
  }
  td {
    text-align: center;
  }
  td.asset {
    text-align: right;
  }
  .total {
    font-weight: bold;
  }
  th,
  td {
    padding: 5px;
    font-size: small;
  }
  tr:nth-child(even) {
    background: #f2f2f2;
  }
  th.payment {
    background: #fff6da;
  }
  td.payment {
    text-align: right;
  }
  tr:nth-child(even) td.payment {
    background: #f6e1a2;
  }
  tr:nth-child(odd) td.payment {
    background: #fff6da;
  }

  th.income {
    background: #bceebf;
  }
  td.income {
    text-align: right;
  }
  tr:nth-child(even) td.income {
    background: #bceebf;
  }
  tr:nth-child(odd) td.income {
    background: #e9f8ea;
  }
}

.tableScroller {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transform: rotateX(180deg);

  table {
    transform: rotateX(180deg);
  }
}

.fixedHeader {
  background: #ffffff;
  position: absolute;
  top: 5px;
  table {
    width: 53px;
  }
}
