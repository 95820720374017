.category {
  margin: auto;
  height: 174px;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}

.title {
  margin-top: 20px;
  margin-left: 20px;
  width: 140px;
  height: 46px;
  flex: 0 0 80px;
  
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  
  /* GRAY_SCALE/NON_TRANSPARENT/BLACK */
  
  color: #212121;
}

.button {
  margin-left: 20px;
  flex: 0 0 50px;
}

.icon img {
  position: absolute;
  width: 25%;
  top: 20px;
  right: 20px;
}
