body {
    font-family: "Noto Sans JP", sans-serif;
}
/*本文*/
.text {
    color: #212121;
    line-height: 1.6;
    font-size: 16px;
}
/*画像*/
.img {
    width: 100%;
    max-width: 680px;
}
/*ボタン*/
.button {
    font-size: 17px;
    font-weight: bold;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    padding: 14px 32px 16px 32px;
    display: inline-block;
    text-decoration: none;
    width: auto;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
    &.primary {
        color: #ffffff;
        background: #49c54f;
    }
    &.secondary {
        color: #3fb445;
        background: #ffffff;
    }
}

/*タイトル*/
.title {
    color: #212121;
    font-weight: bold;
    line-height: 1.5;
    font-size: 24px;
    margin-bottom: 24px;

    @media screen and (min-width: 600px) {
        text-align: center;
        margin-bottom: 32px;
    }
    @media screen and (min-width: 960px) {
        font-size: 25px;
    }
    .sub {
        color: #3fb445;
        font-size: 14px;
        display: block;
        margin-bottom: -webkit-calc(16px - 32px * 0.5 / 2 - 18px * 0.5 / 2);
        margin-bottom: -moz-calc(16px - 32px * 0.5 / 2 - 18px * 0.5 / 2);
        margin-bottom: calc(16px - 32px * 0.5 / 2 - 18px * 0.5 / 2);
        @media screen and (min-width: 600px) {
            margin-bottom: -webkit-calc(32px - 32px * 0.5 / 2 - 18px * 0.5 / 2);
            margin-bottom: -moz-calc(32px - 32px * 0.5 / 2 - 18px * 0.5 / 2);
            margin-bottom: calc(32px - 32px * 0.5 / 2 - 18px * 0.5 / 2);
        }
    }
    &.centerOnSP {
        text-align: center;
    }
    &.sizeS {
        font-size: 20px;
    }
}
/*緑色のタイトル補助（PC）*/
@media screen and (min-width: 960px) {
    .title br {
        display: none;
    }
}

/*div*/
.div {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 32px;
    @media screen and (min-width: 600px) {
        margin-top: 80px;
        padding-left: 15%;
        padding-right: 15%;
    }
    @media screen and (min-width: 960px) {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        max-width: 1280px;
        margin: 100px auto 0;
    }
    &.grayback {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            background: #f6f6f6;
            left: 0;
            width: 100%;
            z-index: -1;
            @media screen and (min-width: 1280px) {
                left: -webkit-calc(-1 * (100vw - 1280px) / 2);
                left: -moz-calc(-1 * (100vw - 1280px) / 2);
                left: calc(-1 * (100vw - 1280px) / 2);
                width: 100vw;
            }
        }
    }
}

/*上部固定ヘッダー*/
.l-header {
    height: 56px;
    background: #ffffff;
    -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.12));
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.12));
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    .logo {
        height: auto;
        margin: 16px;
    }
    /*ログインボタンへの遷移（SP:ハンバーガー）*/
    .l-toService {
        margin-right: 16px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
    /*SPならログインボタン・登録ボタンを非表示*/
    .l-accountButton {
        display: none;
        /*タブレット以上ならログインボタン・登録ボタンを直置き*/
        @media screen and (min-width: 600px) {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
        }
    }
    .l-accountButton.open {
        /*SPならハンバーガーメニューをタップでログインボタン・登録ボタンを表示*/
        @media screen and (max-width: 499px) {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -webkit-flex-direction: column-reverse;
            -moz-box-orient: vertical;
            -moz-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            position: fixed;
            left: 0;
            top: 56px;
            padding-top: 8px;
            width: 100%;
            background: #ffffff;
            .button {
                margin: 0 16px 8px;
            }
        }
        @media screen and (max-width: 499px) and (min-width: 600px) {
            all: unset;
        }
    }
    /*タブレット以上ならメニューアイコンを非表示*/
    @media screen and (min-width: 600px) {
        .l-menuIcon {
            display: none;
        }
    }
    .button {
        font-size: 14px;
        padding: 8px 24px;
        font-weight: 600;
        display: block;
        text-align: center;
    }
}

/*トップ*/
.l-top {
    background: #fff7da;
    padding-top: 64px;
    /*トップ（タブレット）*/
    @media screen and (min-width: 600px) {
        display: grid;
        grid-gap: 8px;
        text-align: center;
    }
    .l-topTitle {
        margin: auto;
        /*トップの本文（タブレット）*/
        @media screen and (min-width: 600px) {
            display: inline-block;
            justify-self: right;
        }
        .sub {
            margin-bottom: 16px;
            @media screen and (min-width: 600px) {
                font-size: 18px;
            }
        }
        /*トップのCVボタン*/
        .l-topStartLink {
            text-align: center;
        }
        /*トップ画像（PC）*/
        .pcImage {
            display: none;
            @media screen and (min-width: 600px) {
                display: inline-block;
            }
        }
        /*トップ画像（スマートフォン）*/
        .spImage {
            display: none;
            @media screen and (max-width: 599px) {
                display: block;
                width: 80%;
                margin: 16px auto;
            }
        }
    }
    /*トップのタイトル*/
    .title {
        font-size: 40px;
        font-size: 7.5vw;
        line-height: 1.4;
        margin-bottom: 0;
        letter-spacing: 0;
        text-align: center;
        /*トップのタイトル（タブレット小）*/
        @media screen and (min-width: 600px) {
            font-size: 4vw;
            text-align: center;
        }
        /*トップのタイトル（タブレット大）*/
        @media screen and (min-width: 768px) {
            font-size: 40px;
        }
        /*トップのタイトル（PC小）*/
        @media screen and (min-width: 960px) {
            font-size: 44px;
        }
    }
    /*トップのテキスト*/
    .text {
        margin-top: 16px;
        margin-bottom: 8px;
        text-align: center;
        /*トップのテキスト（PC）*/
        @media screen and (min-width: 960px) {
            max-width: 490px;
            font-size: 21px;
        }
    }
    .attention {
        margin-top: 24px;
        font-size: 14px;
        text-align: center;
        padding: 0 16px;
        @media screen and (max-width: 599px) {
            margin-bottom: 8px;
        }
    }
    /*トップの画像*/
    .img {
        margin: 0;
    }
    .l-topButton {
        padding-bottom: 32px;
        text-align: center;
    }
}
.l-content {
    background-color: #ffffff;
    /*見出し*/
    h2.title {
        text-align: center;
    }
    .text {
        margin-bottom: 8px;
    }
    .l-concept {
        padding-top: 40px;
        margin-top: 0;
        margin-bottom: 64px;
        @media screen and (min-width: 960px) {
            .text {
                text-align: center;
            }
        }
    }
    .div .img {
        display: block;
        margin: 24px auto 0 auto;
    }

    /*通常のタイトル*/
    @media screen and (min-width: 960px) {
        .title {
            text-align: center;
            font-size: 28px;
        }
        .div .img {
            width: 80%;
            max-width: unset;
            grid-gap: 80px;
        }
    }
    /*コンセプトのタイトル*/
    @media screen and (min-width: 600px) {
        .div {
            grid-gap: 20px;
            &:nth-child(odd) .l-conceptText {
                -webkit-box-ordinal-group: 3;
                -webkit-order: 2;
                -moz-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                justify-self: left;
            }
            &:nth-child(odd) .img {
                justify-self: right;
            }
            .img {
                max-width: 375px;
            }
        }
        .l-conceptText {
            justify-self: right;
        }
    }
    .l-step {
        margin-bottom: 48px;
        @media screen and (min-width: 960px) {
            .text {
                text-align: center;
            }
        }
        .l-stepItem {
            background: #fff6da;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 16px;
            margin-bottom: 16px;
            border-radius: 10px;
            @media screen and (min-width: 600px) {
                margin-bottom: 16px;
                text-align: center;
            }
            p,
            h3 {
                margin: 0;
                padding: 0;
            }
            .title {
                margin-bottom: 8px;
                @media screen and (min-width: 600px) {
                    font-size: 23px;
                }
            }
            .l-stepItemText {
                margin-bottom: 8px;
                .text {
                    margin-bottom: 8px;
                }
                .sub {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 1.5;
                    margin: 8px 0;
                }
            }
        }
    }
}

.clear {
    clear: both;
}

.title.sizeS {
    margin-bottom: 8px;
}

.l-topStudentLink {
    margin-top: 8px;
    a {
        font-size: 80%;
        color: #212121;
        &:hover {
            text-decoration: none;
        }
    }
}

.l-letsStart {
    h3 {
        margin: 0;
        padding-left: 8px;
    }
    p {
        margin: 0;
        padding: 0;
    }
    .title {
        text-align: left;
        display: inline-block;
    }
    .premiumIcon {
        width: 24px;
        margin-right: 4px;
        position: relative;
        top: 6px;
    }

    .l-premiumItem {
        p {
            margin: 0 0 12px 36px;
        }
        .title {
            font-size: 18px;
        }
    }

    .l-premium .sub {
        font-weight: bold;
        text-align: left;
        margin: 48px 0 24px;
    }
}

.l-premiumItem {
    background: #f5f5f5;
    text-align: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.l-premiumItemTitle {
    text-align: left;
}

.l-premiumItem .icon {
    max-width: 30px;
    margin-right: 8px;
    vertical-align: text-bottom;
}

.l-bottomButton {
    text-align: center;
    margin-top: 48px;
    padding-bottom: 56px;
}

.bottomButton {
    display: inline-block;
}

.l-letsStart .title .h2 {
    margin-bottom: 16px;
}

@media screen and (max-width: 499px) {
    .l-letsStart .button {
        width: auto;
        display: block;
    }
}

.l-letsStart .l-stores {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.plus {
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
}

.l-disclaimer {
    margin: 48px 0;
}

.l-disclaimer .title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}

.l-disclaimer .text {
    font-size: 12px;
    margin-bottom: 40px;
    display: inline;
}

.l-footer {
    margin-top: 0;
    .l-foorerLink {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @media screen and (min-width: 600px) {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
            -moz-box-orient: horizontal;
            -moz-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }
    .l-footerLinkItem {
        margin: 12px 0;
        text-decoration: none;
        color: #212121;
        display: inline-block;
        @media screen and (min-width: 600px) {
            margin: 12px 16px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.l-footerGrayback {
    padding-top: 8px;
    padding-bottom: 56px;
    &:after {
        height: -webkit-calc(100%);
        height: -moz-calc(100%);
        height: calc(100%);
        top: 0;
    }
}

table {
    margin: 16px auto;
    width: 100%;
    tbody th {
        width: 50%;
    }
    tbody td {
        width: 25%;
    }

    @media screen and (min-width: 580px) {
        width: 500px;
        tbody th {
            width: 300px;
            font-size: 100%;
        }
        tbody td {
            width: 100px;
            font-size: 100%;
        }
    }
    tr.account th,
    tr.price th {
        text-align: center;
    }
    tr.account {
        color: #ffffff;
        th.free {
            background-color: #3fb445;
        }
        th.premium {
            background-color: #f29024;
        }
    }
    tr.price {
        th {
            font-size: 90%;
            &.free {
                background-color: #E6F7E7;
                color: #3fb445;
            }
            &.premium {
                background-color: #FFF5D5;
                color: #f29024;
            }
        }
    }
    th {
        text-align: left;
        padding: 4px 8px;
    }
    tbody th {
        font-weight: normal;
    }
    tr.category th {
        font-weight: bold;
    }
    td {
        padding: 4px 8px;
        text-align: center;
        &.free {
            background-color: #E6F7E7;
            color: #3fb445;
            font-weight: bold;
        }
        &.premium {
            background-color: #FFF5D5;
            color: #f29024;
            font-weight: bold;
        }
    }
}
