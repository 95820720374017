.summary {
  margin: 0 20px 10px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* GRAY_SCALE/NON_TRANSPARENT/BLACK */

  color: #212121;
}

.selectAgeContainer {
  display: flex;
  margin: 20px;
}

.showAsset {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
}

.showAssetPlus {
  @extend .showAsset;
  color: black;
}

.showAssetMinus {
  @extend .showAsset;
  color: rgba(224, 69, 69, 0.87);
}
.update {
  text-align: center;
}
