.area {
  display: flex;
}
.partsFirst {
  display: block;
  margin-left: 20px;
  text-align: left;
}

.partsSecond {
  display: block;
  margin-right: 20px;
  text-align: right;
}
