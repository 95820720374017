.chart {
  font-weight: "bold";
}

:global .recharts-reference-dot {
  overflow: visible;
}

.dotText {

font-style: normal;
font-weight: bold;
font-size: 14px;

/* BRAND/PRIMARY */

fill: #FFFFFF;
}