$themeColor: #3FB445;
$foregroundColor: #daf0db;
$disabledColor: rgba(0, 0, 0, 0.12);

.btn {
  display: inline-block;
  font-size: 1rem;
  margin: 0 8px 4px 0;
  min-width: 10em;
  height: 44px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 32px;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  background: $themeColor;
  border: 1px $themeColor solid;

  &_disabled {
    @extend .btn;
    background: $disabledColor;
    border-color: $disabledColor;
  }
}
