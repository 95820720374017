.return {
  padding: 0 0 108px 24px;
}

.field {
  width: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  :global .MuiFormControl-root {
    display: inline !important;
  }
}

.type {
  width: 36px;
  height: 16px;
  padding-top: 5px;
  margin-right: 8px;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  border-radius: 19px;
  color: #ffffff;
}

.type-config {
  @extend .type;
  background: #9e9e9e;
}

.type-income {
  @extend .type;
  background: #3fb445;
}

.type-expense {
  @extend .type;
  background: #f3af17;
}

.type-investment {
  @extend .type;
  background: #e477ee;
}

.type-asset {
  @extend .type;
  background: #42bfe6;
}

.fieldContainer {
  padding: 12px;
  display: flex;
}

.valueTitle {
  font-weight: bold;
  display: flex;
}

.child .valueTitle {
  font-weight: normal;
}

.valueTitle img {
  margin-left: 6px;
  width: 16px;
  cursor: pointer;
}

.valueSubtitle {
  font-weight: normal;
  font-size: 85%;
  color: #333333;
}

.valueComment {
  font-size: 70%;
  a {
    color: #666666;
  }
}

.warning {
  font-size: 70%;
  margin-top: 4px;
  color: #e20044;
}

.total {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
}

.total .row {
  display: flex;
  width: 600px;
}
.helpDialog {
  max-width: 480px;
  margin: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #6dd172;
    border-radius: 3px;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .vertical {
    width: 100%;
    border-collapse: collapse;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin-bottom: 8px;
    margin-left: 24px;
  }
  :global .MuiDialogContent-root {
    > div {
      font-size: 14px;
      padding: 0;
      h3:first-child {
        margin-top: 0;
      }
      a {
        color: #333333;
      }
      p {
        padding: 0;
      }
      tr {
        border-bottom: solid 2px white;
      }

      tr:last-child {
        border-bottom: none;
      }

      th {
        position: relative;
        text-align: left;
        width: 30%;
        background-color: #daf0db;
        color: #3fb445;
        text-align: center;
        padding: 10px 0;
      }

      th:after {
        display: block;
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        top: calc(50% - 10px);
        right: -10px;
        border-left: 10px solid #daf0db;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }

      td {
        text-align: left;
        width: 70%;
        text-align: center;
        background-color: #fafafa;
        padding: 10px 0;
      }
    }
  }
}
