.container {
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0;
  padding: 16px;
}

.row {
  display: flex;
  flex-wrap: nowrap;
}

.age {
  flex: 0 0 46px;
  position: relative;
  font-weight: 600;
  font-size: 13px;
}

.dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-clip: content-box;
  background-color: #E0E0E0;
  position: absolute;
  left: 42px;
  top: 5px;
  display: inline-block;
}

.description {
  flex-grow: 5;
  border-left: 1px solid #e0e0e0;
  padding-left: 16px;
  padding-bottom: 10px;
}

.event {
  display: flex;
  > div:first-child {
    padding-right: 6px;
  }
}

.index {
  width: 20px;
  height: 20px;
  border: 5px solid rgba(63,180,69, .5);
  border-radius: 50px;
  background-clip: content-box;
  background-color: rgb(63,180,69);
  position: absolute;
  left: -35px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.eventTitle {
  font-weight: normal;
  font-size: 13px;
}
