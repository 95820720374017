.container {
  margin: 0 16px 32px 16px;
  position: relative;
}

.reviews {
  display: flex;
  padding-top: 16px;
}

.checkbox {
  margin: 0 4px 8px 0;
  cursor: pointer;
}

.item {
  display: flex;
  margin-left: 8px;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
}

.reviews + .reviews {
  border-style: solid none none none;
  border-width: 1px;
  border-color: rgba(197, 185, 185, 0.2);
  box-sizing: border-box;
}

.title {
  font-weight: bold;
  font-size: 15px;
}

.description {
  font-size: 11px;
  margin-bottom: 12px;
}

.edit {
  font-size: 13px;
  line-height: 16px;
  height: 16px;
  position: relative;
  top: -6px;
  color: #3fb445;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content.unchecked {
  .title {
    color: #212121;
  }
  .description {
    color: #666666;
  }
}

.content.checked {
  .title {
    color: #9e9e9e;
  }
  .description {
    color: #9e9e9e;
  }
}

.completedTitle {
  text-align: center;
  font-size: 14px;
}
