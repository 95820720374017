.table {
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border: 10px solid #f0f0f0;
  background-color: #f0f0f0;
  th {
    cursor: pointer;
  }

  td {
    text-align: left;
    background-color: #ffffff;
    &.genre {
      white-space: nowrap;
    }
    &.amount {
      text-align: right;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
}
img {
  padding-top: 2px;
}
.total {
  margin-right: 8px;
  margin-top: 4px;
}
.headerText {
  flex-grow: 1;
  > div {
    margin-top: 4px;
  }
}
