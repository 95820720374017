.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #F0F0F0;
  width: 100%;
  height: 64px;
  display: flex;
  z-index: 10;
}

.container {
  margin: auto;
  width: 600px;
  font-size: 17px;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.totalWrapper {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.title {
  padding-left: 8px;
  font-size: 12px;
}

.total {
  padding-left: 8px;
  font-size: 18px;
}

.plus {
  color: #3FB445;
}

.minus {
  color: #E35C5C;
}

.linkButton {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  margin: 0 4px 0 4px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.linkButton:active {
  background: #eeeeee;
}

.linkButtonIcon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
}

.linkButtonTitle {
  font-size: 11px;
  text-align: center;
}
