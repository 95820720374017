.panel-in-enter {
  transition: transform 0.3s ease-out;
}

.panel-in-enter-active {
  transform: translate3d(-50%, 0, 0);
}

.panel-out-enter {
  transition: transform 0.3s ease-out;
}

.panel-out-enter-active {
  transform: translate3d(50%, 0, 0);
}

.panelBoard {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

.panelBoardInAction {
  display: flex;
  width: 200%;
  overflow-x: hidden;
}

.panel {
  display: block;
  width: 100%;
}

.app {
  max-width: 600px;
  margin: auto;
  background-color: #ffffff;
  position: relative;
}

.update {
  max-width: 600px;
  padding: 20px 0;
  margin: auto;
  text-align: center;
  background-color: #ffffff;
}

button:focus {
  outline: 0;
  opacity: 0.8;
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}
