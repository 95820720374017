.title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
}

.comment {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #212121;
}
