@keyframes star-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.QuestionCompletedStar {
  position: "absolute";
  animation: star-blink 1s infinite;
}

.QuestionCompletedButton {
  opacity: 0;
  margin-bottom: 200px;
}

.QuestionCompletedButton-enter {
  opacity: 0;
  transform: scale(0.8);
}

.QuestionCompletedButton-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.QuestionCompletedButton-enter-done {
  opacity: 1;
}

.description {
  text-align: center;
  margin: auto 20px 20px 20px;
}
