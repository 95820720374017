.content {
  width: 100%;
  margin-top: 80px;
  text-align: center;
  :global .MuiFormControl-root {
    display: inline;
  }
  .description {
    margin: auto 20px 32px 20px;
    font-size: 14px;
  }
  .later {
    cursor: pointer;
    margin-top: 8px;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
}

.warning {
  margin-top: 4px;
  font-size: 70%;
  color: #e20044;
}
.comment {
  margin: 8px 16px;
  font-size: 14px;
  color: #333333;
}
