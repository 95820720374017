$foregroundColor: #5abc60;
.defaultInput {
  width: 80%;
  /*height: 34px;*/
  padding: 4px 5px;
  margin-top: 4px;
  /*font-size: 32px;*/
  font-size: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #212121;
  border-radius: 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
}

/*
:global .settingsField {
  :local .defaultInput {
    height: 24px;
    font-size: 20px;
  }
}
*/