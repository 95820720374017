.header {
  position: relative;
  height: 25px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  height: 32px;
}

.fadeoutHeader {
  @extend .header;
  transition: 1s ease-out 1s;
  opacity: 0;
}
