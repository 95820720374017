.settingsType {
  margin: 16px 16px 0 16px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);

  border-style: none none solid none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.06);
}
