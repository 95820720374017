$themeColor: #5abc60;

.header {
  font-weight: bold;
  position: fixed;
  background-color: #f0f0f0;
  height: 48px;
  width: 600px;
  z-index: 100;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  h1 {
    text-align: center;
    font-size: 17px;
    padding-top: 12px;
    margin: 0;
  }
}

.backLink {
  position: absolute;
  color: $themeColor;
  cursor: pointer;
  font-size: 1rem;
  top: 14px;
  left: 16px;
}

.container {
  margin: 0;
  padding-top: 48px;
  padding-bottom: 24px;
  background-color: #f0f0f0;
  h2 {
    margin: 0 0 8px 16px;
  }
}

.section {
  background-color: #ffffff;
  margin: 0 16px 0 16px;

  &.child {
    margin-top: 2px;
  }

  &.default, &.parent {
    margin-top: 8px;
  }
}
