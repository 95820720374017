.hiddenArea {
  height: 92px;
  background-color: #f0f0f0;
}

.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  display: flex;
}
.container {
  margin: auto;
  width: 600px;
}

.total {
  font-weight: bold;
  padding-right: 12px;
  font-size: 20px;
  span {
    font-size: 14px;
  }
}

.plus {
  color: #3FB445;
}

.minus {
  color: #E35C5C;
}

.section {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  &:first-child {
    margin-top: 12px;
  }
  &:last-child {
    margin-bottom: 12px;
    .total {
      font-size: 25px;
    }
  }
}
.title {
  padding-left: 12px;
}
