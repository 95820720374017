.containerDefault {
  display: flex;
  flex-direction: row;
}

.fullWidthContainer {
  @extend .containerDefault;
  padding: 8px;
  margin: 16px;
  background: #FFF6DA;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.container {
  @extend .containerDefault;
  margin: 20px;
  border-radius: 8px;
  padding: 15px;
  background: rgba(73, 197, 79, 0.15);
  border-radius: 8px;
}

.comment {
  padding-left: 10px;
}
