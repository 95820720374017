.content {
  width: 100%;
  margin-top: 80px;
}

.content {
  width: 100%;
  margin-top: 80px;
}
.number {
  display: flex;
  width: 230px;
}
.numberField {
  width: 140px;
  margin-right: 20px;
}
.unit {
  /*font-size: 30px;*/
  margin: 6px 0 6px 6px;
}
